import { Fragment, useEffect, useState } from "react";
import Navigationbar from "./Navbar";
import './home.css';
import './writing.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Container, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

function Writing() {
    const [mediumPosts, setMediumPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPost = async () => {
            const response = await fetch("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@indraaristya");

            if (!response.ok) {
                console.error('Failed to fetch medium posts');
            } else {
                const data = await response.json();
                setMediumPosts(data['items']);
            }
            setLoading(false);
        }
        fetchPost()
    }, []);

    return (
        <Fragment>
            <Navigationbar />
            <div className="body">
                <h3>Medium Posts</h3>
                <Spinner animation="border" role="status" hidden={!loading}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>

                { mediumPosts.length === 0 && !loading && 
                    <div>
                        <br></br>
                        <p className="title">Failed to fetch stories. Go checkout on
                            <a className="title" href="https://medium.com/@indraaristya" target="_blank" rel="noreferrer"> Medium Stories</a>
                        </p>
                    </div>
                }
                
                <Container>
                    <Row>
                        { mediumPosts.map((post, _index) => (
                            <Card className="posts">
                                {/* <Card.Img variant="top" src="https://cdn.icon-icons.com/icons2/2997/PNG/512/medium_logo_icon_187624.png" className="card-image"/> */}
                                <Card.Body className="card-body">
                                    <Card.Title className="title">{post['title']}</Card.Title>
                                    <Card.Text className="subtitle">Published at <i>{post['pubDate'].split(' ')[0]}</i></Card.Text>
                                    <Card.Text className="subtitle">Categories: <i>{post['categories'].join(', ')}</i></Card.Text>
                                    
                                </Card.Body>
                                <Card.Footer>
                                    <a className="mt-auto" href={post['guid']} download={post['title']} target='_blank' rel="noreferrer">
                                        <Button className="read-more mt-auto subtitle">Read more</Button>
                                    </a>
                                </Card.Footer>
                            </Card>
                        ))}
                    </Row>
                </Container>
            </div>
        </Fragment>
    )
}
  
export default Writing;