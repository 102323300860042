import { Fragment } from "react";
import "./experience.css";
import "./project.css";
import { Row, Col, Card, Button, Stack, Badge } from "react-bootstrap";

function Projects() {
    const projects = [
        {
            "title": "Playwright Report to Slack",
            "subtitle": "Library of custom report for Playwright",
            "url": "https://github.com/indraaristya/playwright-slack-report",
            "description": "Library of custom report for Playwright automation to send automation result to Slack channel.",
            "tech_stack": ["NodeJS"],
            "logo": "https://i.ibb.co.com/ng60bp2/Instagram-post-1.png"
        },
        {
            "title": "QA Projects",
            "subtitle": "Automate API & WEB Cases",
            "url": "https://github.com/indraaristya/test-automation-portfolios",
            "description": "Create the test cases to test the product both for API and WEB. The test cases also automated using Playwright and able to run in Github Action.",
            "tech_stack": ["Playwright"],
            "logo": "https://i.ibb.co.com/Lkn0PkC/Black-And-White-Aesthetic-Minimalist-Modern-Simple-Typography-Coconut-Cosmetics-Logo-1.png"
        },
        {
            "title": "StampYourMoments",
            "subtitle": "Image Watermark tools",
            "url": "https://stampyourmoments.my.id",
            "description": "The main idea is adding watermark on several images at once without opening and add the text to a bunch of images one-by-one. By using this web app, users expected to be able add watermark using text or their own watermark images to a lot of images in 1 click. ",
            "tech_stack": ["NextJS"],
            "logo": "https://i.ibb.co.com/J7353DM/Instagram-post-1-1.png"
        },
        {
            "title": "SplitBills!",
            "subtitle": "Split Bill Multi Receipts",
            "url": "https://www.split-bills.site/",
            "description": "Better than common split bills tools, I offer to calculate the bills with your friends from multiple receipts. Only by 1 time input data, all the calculation will be given.",
            "tech_stack": ["NextJS"],
            "logo": "https://i.ibb.co.com/nDMfLdK/Cropped-Image.png"
        }
    ]

    return (
        <Fragment>
           <Row xs={1} md={3} className="g-4">
            { projects.map(function(object, idx) {
                return (
                    <Fragment>
                        <Col key={idx}>
                            <Card style={{ width: '100%', height: '100%' }}>
                                <Card.Img variant="top" className="image-project" src={object['logo']} alt="logo-stamp"/>
                                <Card.Body>
                                    <Card.Title><h4>{object['title']}</h4></Card.Title>
                                    <Card.Subtitle>{object['subtitle']}</Card.Subtitle>
                                    <br>
                                    </br>
                                    <Card.Subtitle>
                                    <Stack direction="horizontal" gap={2}>
                                        { object['tech_stack'].map(function(tech, _i) {
                                            return <Badge pill bg="info">
                                                    {tech}
                                                </Badge>
                                        })}
                                    </Stack>
                                    </Card.Subtitle>
                                    <br></br>
                                    <Card.Text>
                                        <p className="desc">{object['description']}</p>
                                    </Card.Text>
                                    <Button className="button-content" href={object['url']} target="_blank" rel="noreferrer">Check it!</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Fragment>
                )
            })}
            </Row>
        </Fragment>
    )
}
  
export default Projects;